import { Card, Grid, Typography } from '@mui/material'

import UpcomingAppointments from 'src/views/scheduled/UpcomingAppointments'
// ** Icon Imports
import { GuardWrapper } from 'src/layouts/GuardWrapper'

const ScheduledPatients = props => {
  return (
    <GuardWrapper {...props}>
      <Typography align='center' variant='h4' sx={{ mb: 4, mt: 4 }}>
        Scheduled Patients
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3, boxShadow: 3 }}>
            <UpcomingAppointments />
          </Card>
        </Grid>
      </Grid>
    </GuardWrapper>
  )
}

export default ScheduledPatients
