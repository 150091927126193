// ** Styled Component Import
import Typography from '@mui/material/Typography'
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker'

// ** Icon Imports
import { GuardWrapper } from 'src/layouts/GuardWrapper'

import StepperLinearWithValidation from 'src/views/addPatient/linearStepperWithValidation/StepperLinearWithValidation'
const AddNewPatient = props => {
  return (
    <GuardWrapper {...props}>
      <Typography align='center' variant='h4' mb='20px' mt='20px'>
        Add New Patient
      </Typography>
      <DatePickerWrapper>
        {' '}
        <StepperLinearWithValidation />
      </DatePickerWrapper>
    </GuardWrapper>
  )
}

export default AddNewPatient
