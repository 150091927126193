import { axiosClient } from 'src/configs/axios'

// Get all patients
export const getPatientsAPI = async () => {
  return axiosClient.get('/patient')
}

// Create a new patient
export const createPatientAPI = async payload => {
  try {
    const response = await axiosClient.post('/patient/create', payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    const patientId = response?.data?.patient?._id

    return patientId
  } catch (error) {
    console.error('Error creating patient:', error)
    throw error
  }
}

// Edit an existing patient
export const editPatientAPI = async (id, payload) => {
  try {
    const response = await axiosClient.patch(`/patient/${id}`, payload) // Pass payload here
    return response?.data
  } catch (error) {
    console.error(`Error updating patient with ID ${id}:`, error)
    throw error
  }
}


// Delete a patient
export const deletePatientAPI = async id => {
  try {
    const response = await axiosClient.delete(`/patient/${id}`)

    return response?.data
  } catch (error) {
    console.error(`Error deleting patient with ID ${id}:`, error)
    throw error
  }
}
