import React from 'react'
import { Typography, Grid, Card, CardContent, Box, Avatar } from '@mui/material'
import Icon from 'src/@core/components/icon'

const dummyAppointments = [
  {
    id: 1,
    date: '2024-08-10T10:00:00Z',
    patientName: 'John Doe',
    doctorName: 'Dr. Smith',
    appointmentType: 'Consultation'
  },
  {
    id: 2,
    date: '2024-08-11T11:00:00Z',
    patientName: 'Jane Doe',
    doctorName: 'Dr. Adams',
    appointmentType: 'Follow-up'
  },
  {
    id: 3,
    date: '2024-08-12T12:00:00Z',
    patientName: 'Alice Johnson',
    doctorName: 'Dr. Brown',
    appointmentType: 'Routine Check-up'
  },
  {
    id: 4,
    date: '2024-08-12T12:00:00Z',
    patientName: 'Nizar',
    doctorName: 'Nizar',
    appointmentType: 'Routine Check-up'
  }
]

const UpcomingAppointments = () => {
  return (
    <Box m={4}>
      <Typography variant='h6' gutterBottom>
        Upcoming Appointments
      </Typography>
      <Grid container spacing={4}>
        {dummyAppointments.map(appointment => (
          <Grid item xs={12} sm={6} md={3} key={appointment.id}>
            <Card sx={{ borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <CardContent>
                <Box display='flex' alignItems='center' mb={2}>
                  <Avatar sx={{ mr: 2 }}>
                    <Icon icon='mdi:calendar-blank' fontSize='20px' />
                  </Avatar>
                  <Typography variant='p'>{new Date(appointment.date).toLocaleString()}</Typography>
                </Box>
                <Box display='flex' alignItems='center' mb={1}>
                  <Icon icon='mdi:account' fontSize='20px' style={{ marginRight: '8px' }} />
                  <Typography color='textSecondary'>Patient: {appointment.patientName}</Typography>
                </Box>
                <Box display='flex' alignItems='center' mb={1}>
                  <Icon icon='mdi:stethoscope' fontSize='20px' style={{ marginRight: '8px' }} />
                  <Typography color='textSecondary'>Doctor: {appointment.doctorName}</Typography>
                </Box>
                <Box display='flex' alignItems='center'>
                  <Icon icon='mdi:clipboard-text' fontSize='20px' style={{ marginRight: '8px' }} />
                  <Typography variant='body2' color='textSecondary'>
                    Type: {appointment.appointmentType}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default UpcomingAppointments
