import { lazy } from 'react'
import { Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { ErrorBoundary } from 'src/views/errorBoundary'
import { PageComponent } from 'src/layouts/PageComponent'
import AddNewPatient from 'src/pages/addNewPatient'
import Patients from 'src/pages/patients'
import ScheduledPatients from 'src/pages/scheduledPatients/index'
import UpcomingAppointments from 'src/views/scheduled/UpcomingAppointments'
const RootPage = lazy(() => import('src/pages/root'))
const DashboardPage = lazy(() => import('src/pages/dashboard'))
const LoginPage = lazy(() => import('src/pages/login'))


export const AppRouter = ({ children }) => {
  return (
    <>
      <RouterProvider
        router={createBrowserRouter(
          createRoutesFromElements(
            <Route
              errorElement={<ErrorBoundary />}
              element={
                <PageComponent>
                  <Outlet />
                </PageComponent>
              }
            >
              <Route element={<RootPage />} path='/' />
              <Route element={<DashboardPage />} path='/dashboard' />
              <Route element={<LoginPage />} path='/login' />
              <Route element={<AddNewPatient />} path='/add-patient' />
              <Route element={<Patients />} path='/patients' />
              {/* <Route element={<ScheduledPatients/>} path='Scheduled'/> */}
              <Route element={<UpcomingAppointments/>} path='upcoming-appointments'/>
            </Route>
          )
        )}
      />
      {children}
    </>
  )
}
