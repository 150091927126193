// eslint-disable-next-line import/no-anonymous-default-export
export default {
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        borderRadius: 4
      }
    }
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        borderRadius: 4
      }
    }
  }
}
