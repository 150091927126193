import { getSubdomain } from 'src/utils/getSubdomain'
const subdomain = getSubdomain()
const subdomainToProjectMap = {
  health: 'Health',
  sarahomes: 'Sara Homes',
  shaziapharmacy: 'Shazia Pharmacy'
}

const projectName = subdomainToProjectMap[subdomain] || 'Default'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  meEndpoint: '/jwt/me',
  loginEndpoint: '/jwt/login',
  registerEndpoint: '/jwt/register',
  storageTokenKeyName: `${projectName}-admin`,
  onTokenExpiration: 'refreshToken' // logout | refreshToken
}
