import { createSlice } from '@reduxjs/toolkit'
import { getPatients, deletePatient, editPatient } from './patient.action'

const initialState = {
  isPatientsLoading: false,
  isPatientsLoaded: false,
  isPatientsLoadingFailed: false,
  createPatientError: null,
  patientsCurrentPage: 0,
  patientsTotalPages: 0,
  patients: []
}

const patients = createSlice({
  name: 'patients',
  initialState,

  extraReducers: builder => {
    builder
      // Handle getPatients action
      .addCase(getPatients.pending, state => {
        state.isPatientsLoading = true
        state.isPatientsLoaded = false
        state.isPatientsLoadingFailed = false
      })
      .addCase(getPatients.fulfilled, (state, action) => {
        state.isPatientsLoading = false
        state.isPatientsLoaded = true
        state.patients = action.payload.documents
        state.patientsCurrentPage = action.payload.currentPage
        state.patientsTotalPages = action.payload.totalPages
      })
      .addCase(getPatients.rejected, state => {
        state.isPatientsLoading = false
        state.isPatientsLoadingFailed = true
      })

      //delete

      .addCase(deletePatient.pending, state => {
        state.isDeletingPatient = true
        state.deletePatientError = null
      })
      .addCase(deletePatient.fulfilled, (state, action) => {
        state.isDeletingPatient = false
        state.patients = state.patients.filter(patient => patient.id !== action.payload.id)
      })
      .addCase(deletePatient.rejected, (state, action) => {
        state.isDeletingPatient = false
        state.deletePatientError = action.error.message
      }) //edit
      .addCase(editPatient.fulfilled, (state, action) => {
        state.isEditingPatient = false
        state.isPatientEdited = true
        state.patients = state.patients.map(patient => (patient._id === action.payload._id ? action.payload : patient))
      })
  }
})

export default patients.reducer
