import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-hot-toast'
import { sendSMS } from './api'

export const sendSMSPatient = createAsyncThunk('SendSMSToPatient/sendSMS', async payload => {
  const resultPromise = new Promise((resolve, reject) => {
    sendSMS(payload)
      .then(res => {
        resolve(res)
      })
      .catch(({ response }) => {
        reject(response?.data?.error || 'Something went wrong')
      })
  })

  toast.promise(resultPromise, {
    loading: 'Sending SMS...',
    success: 'SMS Sent successfully',
    error: err => err
  })

  return await resultPromise
})
