// eslint-disable-next-line import/no-anonymous-default-export
export default {
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: 'none'
      }
    }
  }
}
