// ** react router Import
import { Link } from 'react-router-dom'

// ** MUI Components
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled, useTheme } from '@mui/material/styles'

// ** Layout Import
import BlankLayout from 'src/@core/layouts/BlankLayout'

// ** Auth Guard
import { GuardWrapper } from 'src/layouts/GuardWrapper'
// ** Styled Components
const BoxWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '90vw'
  }
}))

const Img = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  marginTop: theme.spacing(4)
}))

const Error404 = props => {
  // ** Hooks
  const theme = useTheme()

  return (
    <GuardWrapper {...props}>
      <Box className='content-center'>
        <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <BoxWrapper>
            <Typography variant='h4' sx={{ mb: 2 }}>
              Page Not Found :(
            </Typography>
            <Typography sx={{ mb: 6, color: 'text.secondary' }}>
              Oops! 😖 The requested URL was not found on this server.
            </Typography>
            <Button to='/' component={Link} variant='contained'>
              Back to Home
            </Button>
          </BoxWrapper>
          <Img width='500' alt='error-illustration' src={`/images/pages/page-misc-error-${theme.palette.mode}.png`} />
        </Box>
      </Box>
    </GuardWrapper>
  )
}
Error404.defaultProps = {
  getLayout: page => <BlankLayout>{page}</BlankLayout>,
  authGuard: false
}

export default Error404
