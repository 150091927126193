// ** Styled Component Import
import { Card } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

// ** Icon Imports
import { GuardWrapper } from 'src/layouts/GuardWrapper'
import { getPatients } from 'src/redux/patient/patient.action'
import { PatientTable } from 'src/views/patients'

const Patients = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPatients())
  }, [dispatch])

  return (
    <GuardWrapper {...props}>
      <Typography align='center' variant='h4' mb='20px' mt='20px'>
        Patient List
      </Typography>
      <Card>
        <PatientTable />
      </Card>
    </GuardWrapper>
  )
}

export default Patients
