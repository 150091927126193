// ** React Imports
import { Fragment, useEffect, useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import { Link } from 'react-router-dom'
import { Card } from '@mui/material'
import DropzoneWrapper from 'src/@core/styles/libs/react-dropzone'

// Styled component for the upload image inside the dropzone area
const Img = styled('img')(({ theme }) => ({
  width: 300,
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(15.75)
  },
  [theme.breakpoints.down('md')]: {
    width: 250,
    marginBottom: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    width: 200
  }
}))

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}))

const FileUploaderMultiple = ({ onFilesChange, clearErrors }) => {
  // ** State
  const [files, setFiles] = useState([])

  // ** Hooks
  const theme = useTheme()

  //   const { getRootProps, getInputProps } = useDropzone({
  //     onDrop: acceptedFiles => {
  //       setFiles(acceptedFiles.map(file => Object.assign(file)))
  //     }
  //   })

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      const updatedFiles = acceptedFiles.map(file => Object.assign(file))
      setFiles(updatedFiles)
      onFilesChange(updatedFiles)
      clearErrors('files')
    }
  })

  useEffect(() => {
    onFilesChange(files)
  }, [files])

  const renderFilePreview = file => {
    if (file.type.startsWith('image')) {
      return <img width={38} height={38} alt={file.name} src={URL.createObjectURL(file)} />
    } else {
      return <Icon icon='bx:file' />
    }
  }

  //   const handleRemoveFile = file => {
  //     const uploadedFiles = files
  //     const filtered = uploadedFiles.filter(i => i.name !== file.name)
  //     setFiles([...filtered])
  //   }

  const handleRemoveFile = file => {
    const updatedFiles = files.filter(f => f !== file)
    setFiles(updatedFiles)
    onFilesChange(updatedFiles)
  }

  const fileList = files.map(file => (
    <ListItem key={file.name}>
      <div className='file-details'>
        <div className='file-preview'>{renderFilePreview(file)}</div>
        <div>
          <Typography className='file-name'>{file.name}</Typography>
          <Typography className='file-size' variant='body2'>
            {Math.round(file.size / 100) / 10 > 1000
              ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
              : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
          </Typography>
        </div>
      </div>
      <IconButton onClick={() => handleRemoveFile(file)}>
        <Icon icon='bx:x' fontSize={20} />
      </IconButton>
    </ListItem>
  ))

  const handleRemoveAllFiles = () => {
    setFiles([])
  }

  return (
    <Fragment>
      <Card sx={{ padding: '1.5rem 3rem' }}>
        <DropzoneWrapper>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <Box sx={{ display: 'flex', flexDirection: ['column', 'column', 'row'], alignItems: 'center' }}>
              <Img alt='Upload img' src={`/images/misc/upload-${theme.palette.mode}.png`} />
              <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: ['center', 'center', 'inherit'] }}>
                <HeadingTypography variant='h5'>Drop files here or click to upload.</HeadingTypography>
                <Typography color='textSecondary' sx={{ '& a': { color: 'primary.main', textDecoration: 'none' } }}>
                  Drop files here or click{' '}
                  <Link href='/' onClick={e => e.preventDefault()}>
                    browse
                  </Link>{' '}
                  thorough your machine
                </Typography>
              </Box>
            </Box>
          </div>
          {files.length ? (
            <Fragment>
              <List>{fileList}</List>
              <div className='buttons'>
                <Button color='error' variant='outlined' onClick={handleRemoveAllFiles}>
                  Remove All
                </Button>
                <Button variant='contained'>Upload Files</Button>
              </div>
            </Fragment>
          ) : null}
        </DropzoneWrapper>
      </Card>
    </Fragment>
  )
}

export default FileUploaderMultiple
