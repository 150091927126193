import { Suspense } from 'react'
import Spinner from 'src/@core/components/spinner'
import { AuthProvider } from 'src/context/AuthContext'

export const PageComponent = ({ children }) => {
  return (
    <AuthProvider>
      <Suspense fallback={<Spinner />}>{children}</Suspense>
    </AuthProvider>
  )
}
