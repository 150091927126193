import { useState } from 'react'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { DataGrid } from '@mui/x-data-grid'
import CustomAvatar from 'src/@core/components/mui/avatar'
import { getInitials } from 'src/@core/utils/get-initials'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'

import { editPatient, deletePatient } from '../../redux/patient/patient.action'

const LinkStyled = styled(Link)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1rem',
  cursor: 'pointer',
  textDecoration: 'none',
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.primary.main
  }
}))

export const PatientTable = () => {
  const dispatch = useDispatch()
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 })
  const { patients } = useSelector(state => state.patient)
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  console.log(patients, 'patients')
  const handleEditClick = patient => {
    setSelectedPatient(patient)
    setEditModalOpen(true)
  }

  const handleDeleteClick = patient => {
    setSelectedPatient(patient)
    setDeleteModalOpen(true)
  }

  const handleEditSave = () => {
    if (selectedPatient && selectedPatient._id) {
      dispatch(editPatient({ id: selectedPatient._id, data: selectedPatient })) // Dispatch edit action with the correct structure
      setEditModalOpen(false)
    } else {
      console.error('Selected patient is invalid or missing ID')
    }
  }

  const handleDeleteConfirm = () => {
    dispatch(deletePatient(selectedPatient._id)) // Dispatch delete action
    setDeleteModalOpen(false)
  }

  const usersColumns = [
    {
      flex: 0.1,
      minWidth: 10,
      field: '_id',
      headerName: 'INDEX'
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: 'name',
      headerName: 'User',
      renderCell: ({ row }) => {
        const { firstName, lastName, email } = row
        const fullName = `${firstName} ${lastName}`

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {renderClient(fullName)}
            <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
              <LinkStyled sx={{ textTransform: 'capitalize' }}>{fullName}</LinkStyled>
              <Typography noWrap variant='caption' sx={{ color: 'text.disabled' }}>
                {email}
              </Typography>
            </Box>
          </Box>
        )
      }
    },
    {
      flex: 0.1,
      minWidth: 80,
      field: 'phoneNumber',
      headerName: 'Phone Number'
    },
    {
      flex: 0.1,
      minWidth: 80,
      field: 'hasInsurance',
      headerName: 'Has Insurance'
    },
    {
      flex: 0.1,
      minWidth: 60,
      field: 'currentStep',
      headerName: 'Current Step'
    },
    {
      flex: 0.15,
      minWidth: 40,
      field: 'dob',
      headerName: 'Date of Birth'
    },
    {
      flex: 0.15,
      minWidth: 40,
      field: 'cinNumber',
      headerName: 'CIN Number'
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.2,
      minWidth: 150,
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', gap: 4 }}>
          <Button variant='outlined' size='small' color='primary' onClick={() => handleEditClick(row)}>
            Edit
          </Button>
          <Button variant='contained' size='small' color='secondary' onClick={() => handleDeleteClick(row)}>
            Delete
          </Button>
        </Box>
      )
    }
  ]

  return (
    <>
      <DataGrid
        autoHeight
        getRowId={row => row?._id}
        rows={patients}
        columns={usersColumns}
        disableRowSelectionOnClick
        pageSizeOptions={[10, 25, 50]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />

      {/* Edit Patient Dialog */}
      <Dialog open={isEditModalOpen} onClose={() => setEditModalOpen(false)}>
        <DialogTitle>Edit Patient</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='First Name'
            type='text'
            fullWidth
            value={selectedPatient?.firstName || ''}
            onChange={e => setSelectedPatient({ ...selectedPatient, firstName: e.target.value })}
          />
          <TextField
            margin='dense'
            label='Last Name'
            type='text'
            fullWidth
            value={selectedPatient?.lastName || ''}
            onChange={e => setSelectedPatient({ ...selectedPatient, lastName: e.target.value })}
          />
          <TextField
            margin='dense'
            label='Email'
            type='email'
            fullWidth
            value={selectedPatient?.email || ''}
            onChange={e => setSelectedPatient({ ...selectedPatient, email: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditModalOpen(false)} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleEditSave} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this patient?</DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const renderClient = row => {
  return (
    <CustomAvatar
      skin='light'
      color={row.avatarColor || 'primary'}
      sx={{ mr: 3, width: 32, height: 32, fontSize: '.875rem' }}
    >
      {getInitials(row ? row : 'John Doe')}
    </CustomAvatar>
  )
}
